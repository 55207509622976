.news-item_links {
  display: flex;
}

.news-item_link {
  box-sizing: border-box;
  padding: 10px;
  color: var(--blue);
}

.news-item__link_disabled {
  color: var(--grey)
}