.technicalSolutionSlider__slider {
  width: 100% !important;
  @media screen and (max-width: 1260px) {
    width: 95% !important;
  }
}

.technicalSolutionSlider__slider .slick-prev, .technicalSolutionSlider__slider .slick-next {
  width: 60px;
  height: 40px;
}