.modal {
  display: flex;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  position: absolute;
  width: 640px;
  top: 310px;
  left: calc(50% - 125px);
  /* bottom: 70px; */
  height: 587px;
}


.modal__close-icon {
  width: 25px;
  background-image: url(../../../images/icons/close-icon.svg);
  height: 25px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-size: 20px;
  margin-right: 10px;
}

.modal-content {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  border-radius: 12px;
  position: absolute;
  // top: 310px;
  // left: calc(50% - 125px);
  background: var(--bs-dropdown-bg);
  max-width: fit-content;
}