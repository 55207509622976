.gost__container {
  display: flex;
  margin-bottom: 20px;
  @media screen and (max-width: 360px) {
    flex-direction: column;
    align-items: center;
  }
}

.gost__container-text {
  @media screen and (max-width: 744px) {
    font-size: 14px;
    line-height: 1.6;
    font-weight: 400;
  }
  @media screen and (max-width: 360px) {
    margin-top: 10px;
    text-align: center;
  }
}