@import url(./vendor/normalize.scss);
@import url(./vendor/fonts/fonts.scss);

:root {
  --black: #000000;
  --white: #FFFFFF;
  --blue: #25BDC1;
  --grey: #aeaaaa;
  --orange: #FE643B;
  --yellow: #E5F270;
  --dark-blue: #2E45A9;
  --light-grey: #272628;
  --background: rgba(255, 255, 255, 0.07);
  --background-light: rgba(255, 255, 255, 0.1);
  --red: #E52A1B;
}

body{
  overflow: hidden !important;
  height: 100vh !important;

}

#root{
  height: 100% !important;
  
}

.page {
  background: var(--black);
  color: var(--white);
  margin: 0 auto;
  font-family: "Inter", "Arial", "Helvetica", sans-serif;
  font-weight: 400;
  font-style: normal;
  height: 100%;
  overflow: hidden auto;
}



// // body {
// //   overflow: hidden !important;
// //   height: 100vh !important;
// // }

// #root {
//  // height: 100% !important;
//  height: 100vh !important;
// }


// .page {
//   background: var(--black);
//   color: var(--white);
//   margin: 0 auto;
//   font-family: "Inter", "Arial", "Helvetica", sans-serif;
//   font-weight: 400;
//   font-style: normal;
//   // min-height: 100vh;

//   // height: 100%;
//   // overflow: hidden auto;
//   display: flex;
//   flex-direction: column;
//   height: 100%;
// }