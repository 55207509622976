.technicalSolutionsButtons__button {
    height: 50px;
    width: 250px;
    border-radius: 100px;
    border: none;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
}

.technicalSolutionsButtons__button_active {
    border: 2px solid var(--blue);
    color: var(--white);
    background-color: transparent;
}