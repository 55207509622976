.footer {
  margin: 20px auto 0;
  padding-top: 20px;
  color: var(--grey);
  background-color: var(--background);
}

.footer__container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.footer__links {
  list-style-type: none;
  padding: 0;
}

.footer__company-name {
  color: white;
  margin: 0 0 6px 0;
}

.footer__company-description {
  font-size: 14px;
}

.footer__list {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.footer__link-img {
  margin-right: 6px;
  height: 15px;
  width: 18px;
}

.footer__caption {
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.footer__links-container {
  @media screen and (max-width: 583px) {
    flex-direction: column;
  }
}
