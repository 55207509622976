.popup {
  display: flex;
  position: fixed;
  color: var(--white);
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity ease 0.3s;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
  z-index: 50;
}

.popup__close-icon {
  width: 32px;
  background-image: url(../../images/icons/close-icon.svg);
  height: 32px;
  position: absolute;
  border: none;
  top: -40px;
  right: 10px;
  background-color: transparent;
  @media screen and (max-width: 540px) {
    height: 20px;
    width: 20px;
    top: -38px;
    right: 7px;
    background-size: contain;
  }
}

.popup__container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  width: 90%;
  align-items: center;
  height: 90%;
  justify-content: center;
}

.slick-track {
  display: flex;
  align-items: center;
}

.slick-arrow {
  z-index: 2;
}

.popup-slider__container {
  width: 100%;
  display: flex !important;
  height: 88vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.popup-slider__image {
  object-fit: contain;
  max-height: 78vh;
  max-width:  97%;
  margin: 0 auto;
}

.popup-slider__image-title {
  margin: 0 auto;
  text-align: center;
}

.video-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container_button {
  position: absolute;
  left: 0;
  right: 0;  
  height: 73%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
}

.play-button__image_active {
  height: 80px;
  width: 80px;
  cursor: pointer;
  background-color: transparent;
  z-index: 2;
}

.play-button__image {
  display: none !important;
}

.custom-slick-dots {
  background-color: white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 2px;
  background-color: #0c2a2b;
  color: white;
  font-size: 12px;
}

.slick-dots .slick-active .custom-slick-dots {
  background-color: var(--blue);
}

.slick-dots li  {
  width: 22px !important;
}