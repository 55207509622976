.editor-popup__image {
  opacity: 0;
  visibility: hidden;
  max-width: 100%;
  margin: 0 auto;
  transition: 2s opacity, 2s visibility;
}

.editor-popup__image_open {
  position: relative;
  opacity: 1;
  visibility: visible;
}

.editor__slider {
  max-width: 95%;
  margin: 0 auto;
  display: flex !important;
  flex-direction: column;
  height: 88vh;
}

.editor__slider-container {
  display: flex !important;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.editor__slider-container_row {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px 0;
}

.editor__slider-container_2img {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.slick-track {
  display: block !important;
}

.editor-popup__img {
  max-width: 60%;
  height: fit-content;
  margin: 0;
}

// .editor-popup__img {
//   object-fit: contain;
//   max-height: 78vh;
//   max-width: 97%;
//   margin: 40px auto 10px;
// }

// .ticker__container {
//   height: 25px;
//   margin: 10px 0;
//   position: relative;
// }

// .ticker__line {
//   position: absolute;
//   top: 0;
//   height: 100%;
//   border-right: 3px var(--blue) solid;
//   animation: cursor 0.8s infinite, typing 20s steps(14) infinite;
// }

// @keyframes cursor {
//   to {
//     border-right: 2px solid #ff7f5000;
//   }
// }

// .ticker__line {
//   // position: absolute;
//   // top: 0;
//   height: 25px;
//   // z-index: 2;
//   // background-color: var(--light-grey);
//   border-left: 3px var(--blue) solid;
//   animation: cursor .8s infinite, typing 20s steps(14) infinite;
// }

// @keyframes cursor {
//   to{
//       border-left: 2px solid #ff7f5000;
//   }
// }