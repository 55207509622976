.intro {
 //background-color: #001C31;
 //padding: 60px 0;
 max-width: 1200px;
 width: 100%;
//  background: linear-gradient(359deg, rgba(0, 0, 0, 0.00) 55.63%, rgba(0, 0, 0, 0.32) 76.72%, rgba(0, 0, 0, 0.80) 98.46%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 24.15%, rgba(0, 0, 0, 0.36) 66.39%, rgba(0, 0, 0, 0.90) 94.12%), radial-gradient(73.98% 155.67% at 52.78% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 42.16%, #000 72.89%), #001C31;
 margin: 20px auto;
}

// .intro__container {

//   //background-color: #001C31;
//   //background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 49.8%, rgba(0, 0, 0, 0.20) 70.56%, rgba(0, 0, 0, 0.50) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 25.8%, rgba(0, 0, 0, 0.20) 60.71%, rgba(0, 0, 0, 0.50) 89.75%), radial-gradient(73.98% 155.67% at 52.78% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 42.16%, #000 72.89%), #001C31;
//   //background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 49.8%, rgba(0, 0, 0, 0.28) 70.56%, rgba(0, 0, 0, 0.70) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 25.8%, rgba(0, 0, 0, 0.28) 60.71%, rgba(0, 0, 0, 0.70) 89.75%), radial-gradient(73.98% 155.67% at 52.78% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 42.16%, #000 72.89%), #001C31;
//   //background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 49.8%, rgba(0, 0, 0, 0.32) 70.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 25.8%, rgba(0, 0, 0, 0.32) 60.71%, rgba(0, 0, 0, 0.80) 89.75%), radial-gradient(73.98% 155.67% at 52.78% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 42.16%, #000 72.89%), #001C31;

// }
// .intro__container {
//   max-width: 1200px;
//   width: 100%;
//   //background-color: #001C31;
//   //background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 49.8%, rgba(0, 0, 0, 0.20) 70.56%, rgba(0, 0, 0, 0.50) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 25.8%, rgba(0, 0, 0, 0.20) 60.71%, rgba(0, 0, 0, 0.50) 89.75%), radial-gradient(73.98% 155.67% at 52.78% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 42.16%, #000 72.89%), #001C31;
//   //background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 49.8%, rgba(0, 0, 0, 0.28) 70.56%, rgba(0, 0, 0, 0.70) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 25.8%, rgba(0, 0, 0, 0.28) 60.71%, rgba(0, 0, 0, 0.70) 89.75%), radial-gradient(73.98% 155.67% at 52.78% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 42.16%, #000 72.89%), #001C31;
//   //background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 49.8%, rgba(0, 0, 0, 0.32) 70.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 25.8%, rgba(0, 0, 0, 0.32) 60.71%, rgba(0, 0, 0, 0.80) 89.75%), radial-gradient(73.98% 155.67% at 52.78% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 42.16%, #000 72.89%), #001C31;
//   background: linear-gradient(359deg, rgba(0, 0, 0, 0.00) 55.63%, rgba(0, 0, 0, 0.32) 76.72%, rgba(0, 0, 0, 0.80) 98.46%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 24.15%, rgba(0, 0, 0, 0.36) 66.39%, rgba(0, 0, 0, 0.90) 94.12%), radial-gradient(73.98% 155.67% at 52.78% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 42.16%, #000 72.89%), #001C31;
//   margin: 20px auto;
// }
// .intro__container {
//   display: grid;
//   max-width: 1200px;
//   grid-template-columns: minmax(200px, 688px) minmax(400px, 479px);
//   grid-template-columns: 60% 40%;
//   margin-left: calc(50/1200 * 100%);
//   align-items: center;
//   grid-template-areas:
//     'name image'
//     'title image'
//     'paragraph image';
//     @media screen and (max-width: 700px) {
//       grid-template-areas:
//       'name name'
//       'title  title'
//       'paragraph image';
//     }
//     @media screen and (max-width: 391px) {
//       grid-template-columns: 1fr;
//       grid-template-areas:
//       'name '
//       'title  '
//       'image'
//       'paragraph';
//     } 
// }

.intro__title {
  grid-area: title;
  font-size: calc(30px + 20 * (100vw / 1200));
  margin: 30px 0 20px;
  @media screen and (min-width: 1200px) {
    font-size: 50px;
  }
  @media screen and (max-width: 700px) {
    width: 94%;
    margin-bottom: 10px;
    font-size: calc(25px + 15 * (100vw / 1200));
  }
  @media screen and (max-width: 391px) {
    margin-bottom: 10px;
    font-size: calc(20px + 10 * (100vw / 1200));
  }
}

// .intro__name {
//   grid-area: name;
// }

.intro__paragraph {
 // grid-area: paragraph;
  font-size: calc(10px + 10 * (100vw / 1200));
 // min-width: 430px;
  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }
  @media screen and (max-width: 700px) {
    width: 94%;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 391px) {
    margin-bottom: 0;
    font-size: calc(16px + 5 * (100vw / 1200));
  }
}

.intro__paragraph {
  // grid-area: paragraph;
   font-size: calc(20px + 10 * (100vw / 1200));
   @media screen and (min-width: 1200px) {
     font-size: 30px;
   }
   @media screen and (max-width: 700px) {
     width: 94%;
     margin-bottom: 10px;
   }
   @media screen and (max-width: 391px) {
     margin-bottom: 0;
     font-size: calc(16px + 5 * (100vw / 1200));
   }
 }

.intro__img {
  //grid-area: image;
  //width: -webkit-fill-available;
  width: 90%;
  max-height: 452px;
  min-width: 200px;
}


.intro__logo {
  max-width: 200px;
}

.intro__logo-img {
  max-width: 200px;
}

.intro__logo-p {
  max-width: 200px;
  text-align: center;
}