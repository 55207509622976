.not-found {
  max-width: 1200px;
  width: 100%;
  padding: 60px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.not-found__title {
  font-style: normal;
  font-weight: 400;
  font-size: 140px;
  line-height: 169px;
  text-align: center;
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: calc(80px + 60 * (100vw / 768));
  }
  @media screen and (max-width: 320px) {
    font-size: calc(30px + 50 * (100vw / 320));
    line-height: 97px;
  }
}

.not-found__description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 5px 0 40px;
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 320px) {
    font-size: 12px;
    margin-bottom: 60px;
  }
}

.not-found__button {
  height: 50px;
  width: 250px;
  border-radius: 100px;
  background: var(--blue);
  border: none;
  color: white;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
}

.not-found__img {
  width: 100%;
  position: absolute;
  bottom: 36px;
  right: 0;
}

.not-found__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 397px;
}
