.parameters__image {
 padding: 20px;
}

.parameters__container {
  width: 80% !important;
}

.slick-prev, .slick-next {
  width: 40px;
  height: 40px;
}

.parameters__logo {
  max-width: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.parameters__logo-img {
  width: 80%;
  margin: 0 auto;
}

.parameters__logo-p {
  text-align: center;
}

.parameters__image-container {
  position: relative;
}

.parameters__zoom-button {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
}