.header {
  width: 100%;
  margin: 0 auto;
  background-color: var(--black);
  position: sticky;
  //position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.nav-links {
  color: var(--white);
  text-decoration: none;
}

.header__container {
  padding: 40px 10px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--black);
  align-items: center;
}

.nav-item_color {
  color: var(--white);
}

.nav {
  @media screen and (max-width: 1034px) {
    width: 100%;
    flex-direction: column;
    align-items: baseline;
    flex-wrap: nowrap;
    margin-top: 10px;
  }
}

// .nav-item {
//   // @media screen and (max-width: 1034px) {
//   //   white-space: normal;
//   //   min-width: 500px;
//   // }

// }

.allNavs {
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.dropdown-item {

  @media screen and (max-width: 1155px) {
    white-space: normal;
    width: 45vw;
  }

  @media screen and (max-width: 991px) {
    white-space: normal;
    width: 100%;
  }

  @media screen and (max-width: 855px) {
    white-space: normal;
    width: 100%;
  }
}
