.automation {
  background-color: var(--background);
}

.automation__container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.automation__columns {
  background: var(--background);
  box-sizing: border-box;
  padding: 30px;
  margin-right: 30px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  position: relative;
  transition: transform 0.4s ease-out;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
}

.automation__columns:hover {
  transform: scale(1.04);
}

.automation__columns:nth-child(2) {
  @media screen and (max-width: 1199px) {
    margin-right: 0;
    margin-left: 30px;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }
}

.automation__columns:nth-child(3) {
  margin-right: 0;
}

.automation__row {
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.automation__img {
  width: 80px;
  border: 3px solid #2c2a2e;
  margin-bottom: 20px;
}
