.information-environment {
  max-width: 1200px;
  width: 100%;
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.information-environment__container-text {
  background-color: var(--light-grey);
  max-width: 350px;
  min-width: 150px;
  width: 100%;
  box-sizing: border-box;
  padding: 26px 25px 20px;
  border-top: 5px solid transparent;
  border-image: linear-gradient(
    50deg,
    black,
    var(--orange),
    var(--orange),
    var(--yellow),
    var(--blue),
    var(--dark-blue)
  );
  border-image-slice: 1;
  //margin-top: 10px;
}

.information-environment__container-img {
  width: 64%;
  min-width: 250px;
}

.information-environment__row-container {
  margin: 20px auto 0;
}
