.news__item {
  @media screen and (max-width: 520px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.news__item-image {
  max-width: 250px;
  min-width: 200px;
  height: fit-content;
  @media screen and (max-width: 520px) {
    margin-bottom: 20px;
    max-width: 340px;
  }
}

.news__item-text {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 520px) {
    flex-direction: column;
    align-items: center;
  }
}

.news__item-description {
  max-width: 702px;
  padding: 0 10px 0 0;
  @media screen and (max-width: 520px) {
    text-align: center;
  }
}

.news__item-date {
  width: 134px;
  // max-width: 145px;
  padding: 0;
  @media screen and (max-width: 520px) {
    text-align: center;
  }
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 0 auto;

  @media screen and (max-width: 981px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 655px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.nolink { 
  text-decoration: none;
}

.grid-item {
  background-color: var(--background);
  color: white;
  padding: 20px;
  text-align: center;
  box-sizing: border-box; /* Учитываем отступы внутри элемента */
  transition: all 0.5s ease-in;

  &:hover {
    background-color: #49423d;
  }
}
