.main {
  max-width: 1200px;
  width: 100%;
  padding: 40px 10px;
  display: flex;
  overflow: inherit;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;


  //flex-grow: 1;
  //overflow-y: auto;
}



.link {
  opacity: 1;
  cursor: pointer;
  text-decoration: none;
  color: var(--grey);
}

.link:hover {
  
  opacity: 0.8;
}

.link_img {
  opacity: 1;
  cursor: pointer;
}

.link_img:hover {
  opacity: 0.5;
}



.text-links {
  color: #96fcff;
  text-decoration: none;
}

.text-links:hover {
  cursor: pointer;
  opacity: 0.5;
}

.description__list {
  margin:  0;
}

.description__number-li {
  list-style: none;
}

.description__number-li::before {content: counter(li); color: var(--blue);
  display: inline-block; width: 1em; margin-left: -1.5em;
  margin-right: 0.5em; text-align: right; direction: rtl}  

.description__number-li {counter-increment: li}

.description__number-li::before {content: counter(li); color: var(--blue);
    display: inline-block; width: 1em; margin-left: -1.5em;
    margin-right: 0.5em; text-align: right; direction: rtl}  
  

.description__circle-li {
  list-style-type: disc;
}

.description__circle-li::marker { color: var(--blue)};

.description__title {
  font-size: 18px;
  margin: 20px 0 10px;
}

.slick-prev:before, .slick-next:before {
  font-size: 30px !important;
}

.slick-dots li button:before {
  color: var(--blue) !important;
}