.rpa__img {
  align-self: center;
  width: 30%;
  @media screen and (max-width: 968px) {
    width: 60%;
    
  }
}

.rpa__table-text {
  border: 1px solid white;
  text-align: center;
  padding: 0 4px;
  @media screen and (max-width: 400px) {
    font-size: 14px;
    
  }
  
}