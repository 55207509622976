.description-developer__container {
  max-width: 1200px;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
//margin-top: 200px
}

.description-developer-slider {
  width: 96% !important;
  margin: 0 auto;
}

.description-developer-slider__img {
  width: 50%;
}

.description-developer-popup__container {
  height: 100%;
}


.description-developer-container__ref {
  height: 200px;
  position: absolute;
  width: 100%;
  top: -200px;
  left: 0;
  z-index: -20;
}

.description-developer__block {
  // margin-top: 200px;
  width: 100%; 
  margin-bottom: 60px;
  position: relative;
}
