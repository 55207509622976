.business-processes__title {
  margin-bottom: 0;
  max-width: 420px;
  text-align: center;
  @media screen and (min-width: 1200px) {
    font-size: 24px;
  }
  @media screen and (max-width: 700px) {
    font-size: calc(14px + 10 * (100vw / 1200));
  }
}