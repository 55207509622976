.advantages {
  max-width: 1200px;
  width: 100%;
  margin:  0 auto;
  padding-top: 40px;
  position: relative;
}

.advantages__columns {
  background: var(--background);
  box-sizing: border-box;
  padding: 20px;
  margin-right: 30px;
  height: 100%;
  transition: transform 0.4s ease-out;
  cursor: pointer;
}

.advantages__columns:hover {
  transform: scale(1.04);
}

.advantages__row {
  width: 98%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.advantages__columns:nth-child(2) {
  margin-top: 50px;
}

.advantages__columns:nth-child(3) {
  margin: 190px 0 0 0;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
}
