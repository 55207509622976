
.developer__description {
  list-style-type: disc;
  cursor: pointer;
}

.developer__description:hover {
  color: var(--grey);
}

.developer__description::marker { color: var(--blue)};